import { Modal } from "react-bootstrap"; // Ensure you have react-bootstrap installed
import {
  OptionCard,
  OptionContainer,
  StyledModalBody,
} from "./ModalShowChoiceCandidat.style";

const ModalShowChoiceCandidat = (props) => {
  const handleChoice = (choice) => {
    console.log(`You selected: ${choice}`);

    if (choice === "Apply for a job") {
      window.location.href = `/projects`;
    }
    if (choice === "Marketplace") {
      window.location.href = `/marketplace`;
    }
  };

  return (
    <Modal show={props.show} onHide={props.onHide} centered={true} >
      <StyledModalBody>
        <OptionContainer>
          <OptionCard onClick={() => handleChoice("Apply for a job")}>
            <h3>Apply for a job</h3>
          </OptionCard>
          <OptionCard onClick={() => handleChoice("ITgalaxy Marketplace")}>
            <h3>Marketplace</h3>
          </OptionCard>
        </OptionContainer>
      </StyledModalBody>
    </Modal>
  );
};

export default ModalShowChoiceCandidat;
