// Modal.js
import styled from "styled-components";

export const SearchBarModalStyle = styled.div`
  display: flex;
`;

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: "113px",
    height: "25px",
    borderRadius: "14px",
    border: "1px solid #ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: state.isSelected ? "#14171F" : "#14171F",
    color: "white",
    fontFamily: "Inter",
    fontSize: "11px",
    margin: "4px 0",
    cursor: "pointer",
    ":active": {
      backgroundColor: "#14171F",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    width: "400px",
    height: "50px", // Adjust height to your preference
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    color: "white",
    fontFamily: "Inter",
    grap: "11px",
    fontSize: "15px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    width: "120px",
    height: "30px",
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#14171F",
    color: "white",
    fontFamily: "Inter",
    fontSize: "15px",
    margin: "4px 0",
    cursor: "pointer",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
    fontWeight: "bold",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "white", // Text color of the selected option in the control area
    backgroundColor: "white", // Background color of the selected option
    fontFamily: "Inter",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    borderRadius: "14px",
  }),
};

export const customStylesSearchModal = {
  option: (provided, state) => ({
    ...provided,
    width: "200px",
    height: "50px",
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "gray",
    color: "black",
    fontFamily: "Bold",
    fontSize: "15px",
    margin: "4px 4px ",
    cursor: "pointer",
    ":active": {
      backgroundColor: "#14171F",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    width: "400px",
    height: "50px",
    overflowY: "scroll", // Adjust height to your preference
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    color: "white",
    fontFamily: "Inter",
    grap: "11px",
    fontSize: "15px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    width: "150px",
    height: "30px",
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#14171F",
    color: "white",
    fontFamily: "Inter",
    fontSize: "15px",
    margin: "4px 0",
    cursor: "pointer",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
    fontWeight: "bold",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    width: "150px",
    height: "30px",
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#14171F",
    color: "white",
    fontFamily: "Inter",
    fontSize: "15px",
    margin: "4px 0",
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    alignItems: "center",
    overflowY: "scroll",
    borderRadius: "14px",
    maxHeight: "200px",
  }),
};

export const SearchTitleStyle = styled.p`
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 20px;
  font-family: "Bricolage Grotesque";
`;

export const SearchColumnstyle = styled.div`
`;
