import { Button } from "react-bootstrap";
import styled from "styled-components";

export const TypeJob = styled.div`
  font-family: Inter, sans-serif;
  font-size: 24px;
  color: #14171f;
  line-height: 30.86px;
  padding-left: 740px;
`;

export const StyleDescription = styled.div`
  font-family: serif;
  font-size: 15px;
  padding-bottom: 40px;
  max-widht: 100px;
  white-space: pre-wrap; /* Preserve line breaks and whitespace */
`;

export const StyleHeader = styled.div`
  display: flex;
`;

export const ModalBodyStyle = styled.div`
  padding-bottom: 20px;
  height: auto;
  max-height: 800px; /* Maximum height for the body */
`;

export const AdvancedSearchButtonModalContainer = styled.button`
  position: fixed;
  margin-left: 780px;
  background-color: black;
  color: white;
  height: 46px;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  grap: 15px;
`;

export const AllTags = styled.span`
  display: flex;
  margin-left: 50px;
  gap: 8px;
  flexwrap: wrap;
`;

export const Tag = styled.div`
  width: 113px;
  height: 34px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #14171f;
  color: white;
  justify-content: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  text-align: center;
`;

export const Bloc3 = styled.div`
  display: flex;
  padding-top: 7px;
  margin-left: 500px;
`;

export const DatePost = styled.div`
  margin-left: 20px;
  margin-right: 70px;
  justify-content: center;
  font-size: 15px;
  color: #2684ff;
  font-weight: 600;
  margin-top: 8px;
  font-style: italic;
`;

export const StyleI = styled.div`
  color: #2684ff;
  font-size: 15px;
  margin-top: 8px;

  .icon {
    padding-right: 10px;
  }
`;

export const BlocTitle = styled.div`
  display: flex;
  align-items: center; /* Center align vertically */
  width: 100%; /* Ensure it uses the full width of its container */
  padding: 10px; /* Add padding around the BlocTitle */
  justify-content: space-between; /* Use space-between to push elements apart */
`;

export const TitleJob = styled.p`
  font-family: Inter, sans-serif;
  font-size: 24px;
  color: white;
  font-weight: 600;
  line-height: 30.86px;
  padding-right: 20px;
  margin-left: 30px;

  /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides overflow */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Allows truncated text with "..." */
`;

export const BudgetWrapperDescript = styled.div`
  flex-shrink: 0; /* Prevent the container from shrinking */
  display: flex;
  justify-content: flex-end; /* Aligns content to the end */
`;

export const Budgetdesc1 = styled.p`
  font-family: Inter, sans-serif;
  border: 1px solid #ccc; // Lighter border for subtle contrast
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Soft shadow for depth
  right: 0;
  font-size: 24px;
  color: white;
  font-weight: 600;
  line-height: 30.86px;
  padding: 20px;
`;

export const Budgetdesc2 = styled.p`
  font-family: Inter, sans-serif;
  right: 0;
  font-size: 24px;
  color: red;
  font-weight: 600;
  line-height: 30.86px;
`;

export const RightButtonContainer = styled(Button)`
  float: right;
  background-color: black;
  height: 4vh;
  margin-top: 2%;
  margin-bottom: 20px;

  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  font-weight: bold;
  font-family: Inter, sans-serif;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const StyledFormText = styled.div`
  font-family: Inter, sans-serif;
  right: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 30.86px;
`;

export const Sections2 = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
`;

export const ApplyButton = styled.button`
  background-color: black;
  color: white;
  width: 100%;
  height: 140%;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  grap: 15px;

  &:hover {
    background-color: #6366f1;
  }
`;

export const PreambleMessage = styled.p`
  color: red; // Text color
  font-size: 16px; // Font size
  font-weight: bold; // Make the text bold
  line-height: 1.5; // Line height for readability
  background-color: #ffe6e6; // Optional: a light red background to emphasize importance
  padding: 10px; // Padding around the text
  border-radius: 5px; // Rounded corners for the background
  border: 1px solid red; // Optional: border to make it stand out
`;

export const BlocShowJob = styled.div`
  width: 100%;
  border-radius: 10px; // Slightly more rounded corners
  background-color: white; // Light background color
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Soft shadow for depth
  padding: 20px;
  height: 100%;
  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); // Enhance shadow on hover
  }
`;

export const BlocShowJobRecruter = styled.div`
  width: 45%;
  margin: 100px auto; // Center the component
  margin: 50px; // Increase padding for a better layout
  border-radius: 10px; // Slightly more rounded corners
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Soft shadow for depth
  height: 90%;

  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); // Enhance shadow on hover
  }
`;

export const BlocShowJobCandidat = styled.div`
  width: 90%;
  padding: 10px; // Increase padding for a better layout
  border-radius: 10px; // Slightly more rounded corners
  background-color: #202124; // Light background color
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Soft shadow for depth

  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); // Enhance shadow on hover
  }
`;

export const BlocShowJobEntreprise = styled.div`
  width: 45%;
  margin: 100px auto; // Center the component
  margin: 50px; // Increase padding for a better layout
  border-radius: 10px; // Slightly more rounded corners
  border: 1px solid #6366f1; // Lighter border for subtle contrast
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Soft shadow for depth
  height: 90%;

  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); // Enhance shadow on hover
  }
`;
